.data-picker{
    height: 50px;
    width: 220px;
    /* box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%); */
    box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
    border-radius: 16px;
    background-color: #0e1729;
    color: #6992fa;
    border-color: transparent;
    font-size: 16px;
    text-align: center;
    /* position: absolute;
    top: 24px;
    right: 24px */
}
.data-picker:focus-visible{
    border-color: transparent;
    outline-color: transparent;
}